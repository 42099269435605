const guesthub = {
  appApiUrl: `${window.location.origin.includes('dashboard')
      ? window.location.origin.replace('dashboard', 'app-api')
      : 'https://app-api.qa.norelian.com'
    }`,
  dashboardApiUrl: `${window.location.origin.includes('dashboard')
      ? window.location.origin.replace('dashboard', 'dashboard-api')
      : 'https://dashboard-api.qa.norelian.com'
    }`,
  subscriptionApiUrl: `${window.location.origin.includes('dashboard')
      ? window.location.origin.replace('dashboard', 'subscription-api')
      : 'https://subscription-api.qa.norelian.com'
    }`
};

export const environment = Object.assign({ production: false }, guesthub);
