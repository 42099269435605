import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable()
export class UrlBuilderService {
  constructor() {}

  requestConfiguration = {
    departments: {
      list() {
        return environment.appApiUrl.concat('/process-builder/departments');
      },
    },
  };

  appApi = environment.appApiUrl;
  dashboardApi = environment.dashboardApiUrl;

  getAllUsers() {
    return environment.appApiUrl.concat("/people-center/users");
  }

  getDashboardMetrics(input) {
    return environment.appApiUrl.concat('/' + input.propertyId + '/dashboard');
  }

  getSettingsWidgetsByUser(input) {
    return environment.appApiUrl.concat(
      '/' + input.propertyId + '/dashboard/widgets/' + input.userId
    );
  }
  getPropertyById(id: number) {
    return environment.subscriptionApiUrl.concat('/property/' + id);
  }
}
